<template>
  <div v-if="!isLoading">
    <b-table-simple
      style="border-radius: 8px; border: 1px solid var(--neutral-300)"
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Horário</b-th>
          <b-th>Paciente</b-th>
          <b-th>Profissionais</b-th>
          <b-th>Sala</b-th>
          <b-th>Pendentes</b-th>
          <b-th>Realizados</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <template v-if="examsList.length && ownerNumber">
        <b-tbody v-for="(exam, index) in examsList" :key="index">
          <b-tr>
            <b-td>
              <div>
                {{ moment(exam?.start_datetime).format('HH[h]mm') }}
              </div>
              <div class="exam-date">
                {{ moment(exam?.start_datetime).format('DD/MM/YYYY') }}
              </div>
            </b-td>

            <b-td>
              <div class="patient-container">
                <PatientInformation
                  :appointment="exam"
                  :status="getExamsStatus(exam)"
                />
                <Export
                  v-can="'FpPac1'"
                  @click="goPatient(exam?.patient?.id)"
                  class="export"
                />
              </div>
            </b-td>
            <b-td>
              <Ellipsis>
                {{ exam?.professional?.name ?? '-' }}
              </Ellipsis>
            </b-td>
            <b-td>
              <Ellipsis>
                {{ exam?.room?.name ?? '-' }}
              </Ellipsis>
            </b-td>
            <b-td
              class="procedure-column pending"
              :class="{
                'not-finished-exams':
                  getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
              }"
            >
              <div v-if="getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'">
                <div>
                  {{
                    textualExamCounting(
                      getAppointmentItemsCountingByStatus(
                        exam,
                        'PENDING',
                        'EXAM'
                      )
                    )
                  }}
                </div>
                <div>
                  {{
                    textualProcedureCounting(
                      getAppointmentItemsCountingByStatus(
                        exam,
                        'OPENED',
                        'PROCEDURE'
                      )
                    )
                  }}
                </div>
              </div>
              <span v-else>0</span>
            </b-td>
            <b-td
              class="procedure-column finished"
              :class="{
                'not-finished-exams':
                  getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
              }"
            >
              <div
                v-if="
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'FINISHED',
                    'EXAM'
                  ) > 0 ||
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'FINISHED',
                    'PROCEDURE'
                  ) > 0
                "
              >
                <div>
                  {{
                    textualExamCounting(
                      getAppointmentItemsCountingByStatus(
                        exam,
                        'FINISHED',
                        'EXAM'
                      )
                    )
                  }}
                </div>
                <div>
                  {{
                    textualProcedureCounting(
                      getAppointmentItemsCountingByStatus(
                        exam,
                        'FINISHED',
                        'PROCEDURE'
                      )
                    )
                  }}
                </div>
              </div>
              <span v-else> 0 </span>
            </b-td>
            <b-td>
              <div class="actions">
                <b-button
                  v-can="'FpExam2'"
                  :disabled="!canStartAttendance(exam)"
                  class="start-button"
                  :class="{ disabled: !canStartAttendance(exam) }"
                  variant="primary"
                  @click="onSelectAppointment(exam)"
                  v-if="!showContinueButton(exam)"
                >
                  Iniciar
                </b-button>
                <b-button
                  v-can="'FpExam2'"
                  v-else-if="showContinueButton(exam)"
                  variant="primary"
                  @click="onSelectAppointment(exam)"
                >
                  Continuar
                </b-button>
                <b-button
                  v-b-toggle="`collapse-${index}`"
                  class="collapse-button"
                  @click="rotateIcon(index)"
                >
                  <ChevronDown
                    class="chevron"
                    :class="rotatedList.includes(index) ? 'rotated' : ''"
                  />
                </b-button>
              </div>
            </b-td>
          </b-tr>
          <tr class="collapsable-row">
            <td :colspan="14">
              <b-collapse :id="`collapse-${index}`" class="collapse">
                <SubEssilorTable
                  :exam="exam"
                  @reload="() => $emit('reload')"
                  @selected-exams="selectedExams"
                />
              </b-collapse>
            </td>
          </tr>
        </b-tbody>
      </template>
    </b-table-simple>
    <NoFilterResults
      v-if="!examsList.length && ownerNumber"
      message="Os filtros aplicados não geraram nenhum resultado"
      class="d-flex flex-column align-items-center"
    />
    <NeedOwnerView
      v-else-if="!ownerNumber"
      message="Não há um código Essilor configurado no sistema"
      buttonTitle="Configurar código"
    />
    <EssilorRoomsModal 
      :start-essilor-attendance="handleStartAttendance" 
      :patient="appointmentToStart?.patient"
    />
  </div>
</template>
<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { isObject } from '@/utils/dataHelper'

export default {
  name: 'EssilorTable',
  components: {
    NeedOwnerView: () => import('@/components/Exam/NeedOwnerView'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    PatientInformation: () =>
      import('@/components/Overview/ScheduleTablePatientInformation'),
    Export: () => import('@/assets/icons/export.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    SubEssilorTable: () => import('@/components/Exam/SubEssilorTable'),
    EssilorRoomsModal: () => import('@/components/Exam/EssilorRoomsModal')
  },
  props: {
    examsList: {
      type: Array
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      ownerNumber: null,
      labpacsClinicsId: null,
      isLoading: false,
      rotatedList: [],
      selected: [],
      appointmentToStart: null
    }
  },
  methods: {
    async getOwnerNumber() {
      const isLoading = this.$loading.show()
      this.isLoading = true
      try {
        const clinicId = this.clinic.id
        const response = await this.api.getEssilorClinicsConfigByClinic(
          clinicId
        )
        if (response.data) {
          this.ownerNumber = response.data.owner_id
          this.labpacsClinicsId = response.data.id
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'Erro ao obter Owner Id. Tente novamente mais tarde ou entre em contato com o suporte.'
        )
        throw error
      } finally {
        isLoading.hide()
        this.isLoading = false
      }
    },
    getExamsStatus(exam) {
      const finished =
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') +
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'PROCEDURE')

      return finished === exam.appointment_items.length
        ? 'ALL_EXAMS_FINISHED'
        : exam.status
    },
    getAppointmentItemsCountingByStatus(appointment, status, type = 'EXAM') {
      return appointment.appointment_items.reduce((ac, it) => {
        if (
          it.attendance &&
          it.attendance.status === status &&
          it.item.type === type
        ) {
          ac++
        }
        if (
          !it.attendance &&
          (status === 'PENDING' || status === 'OPENED') &&
          it.item.type === type
        ) {
          ac++
        }

        return ac
      }, 0)
    },
    goPatient(patientId) {
      const patientRoute = this.$router.resolve(`/pacientes/${patientId}`)
      window.open(patientRoute.href, '_blank')
    },
    /**
     * @param {Number} examsCount
     * @returns {String|Number}
     */
    textualExamCounting(examsCount) {
      return examsCount > 0
        ? examsCount > 1
          ? `${examsCount} Exames`
          : `${examsCount} Exame`
        : ''
    },
    textualProcedureCounting(proceduresCount) {
      return proceduresCount > 0
        ? proceduresCount > 1
          ? `${proceduresCount} Procedimentos`
          : `${proceduresCount} Procedimento`
        : ''
    },
    rotateIcon(id) {
      this.rotatedList.includes(id)
        ? this.rotatedList.pop(id)
        : this.rotatedList.push(id)
    },
    canStartAttendance(exam) {
      return this.selected.some(
        proc => proc.appointment_id === exam.id && proc.attendance === null
      )
    },
    onSelectAppointment(appointment) {
      this.appointmentToStart = appointment
      this.$bvModal.show('essilor-rooms-modal')
    },
    async handleStartAttendance(room) {
      if (!this.appointmentToStart) {
        this.$toast.error('Erro ao iniciar item')
      }
      const itemsToStart = this.selected.filter(
        item => item.appointment_id === this.appointmentToStart.id
      )
      const data = {
        appointment_item_ids: itemsToStart.map(item => item.id),
        room_id: room.roomId,
        room_name: room.roomName,
        patient_id: this.appointmentToStart.patient_id,
        clinic_id: this.clinic.id
      }
      const isLoading = this.$loading.show()
      try {
        await this.api.essilorPatientIn(data)
        if (itemsToStart[0].attendance_id) {
          this.$router.push(
            `/pacientes/${this.appointmentToStart.patient_id}/consulta/${itemsToStart[0].attendance_id}`
          )
          return
        }
        await this.createAttendanceProcedure(itemsToStart)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    selectedExams(value) {
      let hasItem = []
      value.forEach(item => {
        if (item.action === 'add') {
          hasItem = this.selected.filter(slctItem => {
            return slctItem.id === item.procedure.id
          })

          if (hasItem.length === 0) {
            this.selected.push(item.procedure)
          }
        } else if (item.action === 'delete') {
          this.selected = this.selected.filter(slctItem => {
            return slctItem.id !== item.procedure.id
          })
        }
      })
    },
    showContinueButton(exam) {
      const item = this.selected.find(item => item.appointment_id === exam.id)
      if (item) {
        return isObject(item.attendance)
      }
      return false
    },
    async createAttendanceProcedure(proceduresToStart) {
      const isLoading = this.$loading.show()

      try {
        const formId = await this.getDefaultAttendanceForm()
        if (!formId) {
          this.$toast.error(
            'Não foi possivel recuperar o tipo da consulta para o procedimento!'
          )
          return
        }

        const patientId = this.examsList.find(
          exam => exam.id === proceduresToStart[0].appointment_id
        ).patient_id
        const data = {
          ids: proceduresToStart.map(item => item.id),
          appointment: proceduresToStart[0].appointment_id ?? null,
          form_id: formId,
          type: 'ESSILOR_ATTENDANCE'
        }
        const response = await this.api.createAttendanceProcedure(data)
        this.$router.push(`/pacientes/${patientId}/consulta/${response.data}`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getDefaultAttendanceForm() {
      try {
        const response = await this.api.getForms(
          this.clinic.id,
          this.user.id,
          'ESSILOR_ATTENDANCE'
        )
        if (!response.data.length) return
        const form = response.data.some(f => f.default)
          ? response.data.find(f => f.default)
          : response.data[0]
        return form?.id
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  },
  async mounted() {
    await this.getOwnerNumber()
  }
}
</script>

<style lang="scss" scoped>
.essilor-table {
  border: 1px dashed var(--neutral-300);
  border-radius: 8px;
}

.patient-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: initial !important;
  justify-content: flex-start;
}

.not-finished-exams {
  color: orangered !important;
}

.start-button {
  width: 100px;

  &.disabled {
    pointer-events: none;
    background-color: var(--type-placeholder) !important;
    color: var(--type-active) !important;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.rotated {
  rotate: 180deg;
  transition: rotate 1s;
}

.chevron {
  width: 24px;
  height: 24px;
  transition: rotate 1s;
}

.collapsable-row {
  td {
    padding: 0 !important;
    border: none !important;
  }
}

.collapse-button {
  background-color: var(--blue-100);
  border-radius: 4px;
  border: none;
  padding: 4px;
}
</style>
